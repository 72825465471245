import React from 'react';
import { ButtonStyle } from '../../../styles/components/common/ui';

interface IButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  color?: 'primary' | 'secondary' | 'minimal' | 'minimal-secondary' | 'minimal-small' | 'cta' | 'tertiary' | 'outline' | 'outline-small' | 'subtle-small' | 'cta-subtle' | 'in-message';
  onClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  disabled?: boolean;
  type?: 'button' | 'reset' | 'submit';
  style?: React.CSSProperties;
  hasIcon?: boolean;
  darkMode?: boolean;
}

const Button: React.FC<IButtonProps> = (props) => {
  const { ...passThroughProps } = Object.fromEntries(Object.entries(props).filter(([key]) => !['color', 'disabled', 'onClick', 'type', 'hasIcon', 'darkMode'].includes(key)));
  const disabled = props.disabled;
  const color = props.color ? props.color : 'primary';
  const type = props.type ? props.type : 'button';
  const darkMode = props.darkMode;
  const hasIcon = props.hasIcon;
  const getClassNames = () => {
    let classNames = `${ButtonStyle.button} ${darkMode ? ButtonStyle['button-dark-mode'] : ''} ${ButtonStyle['base']} ${hasIcon ? ButtonStyle['has-icon'] : ''} ${ButtonStyle[color]}`;

    if (disabled) {
      classNames += ` ${ButtonStyle['disabled']}`;
    }

    return classNames;
  };
  const reactElement = React.createElement(
    'button',
    {
      ...passThroughProps,
      disabled,
      type,
      onClick: disabled
        ? (elem: React.MouseEvent<HTMLElement, MouseEvent>) => {
            elem.preventDefault();
          }
        : props.onClick,
      className: getClassNames(),
    },
    <>{props.children}</>
  );

  return reactElement;
};

export default Button;
